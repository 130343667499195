<template>
  <div>
    <portal to="pageHeaderRight">
      <contact-dropdown/>
      <span
        @click="cancelEditAsset($route.path)"
        class="ab-button--white ab-button--cancel"
      >{{ $t('app.cancel') }} <i class="icon-close"></i
      ></span>
      <submit-button :isSaving="isSaving" :processing="processing"/>
      <delete-button @delete="removeAsset(asset.slug)"/>
    </portal>

    <div class="asset-edit">
      <form @submit.prevent="formSubmit" @keydown.enter.prevent.self>
        <div class="grid-x grid-margin-x">
          <input type="submit" id="submitForm" hidden=""/>
          <div class="cell medium-12">
            <h2 class="light">{{ $t('assets.edit_asset') }}</h2>
            <div class="spacer--50"></div>
            <div class="grid-x grid-margin-x">
              <div class="cell medium-8">
                <ab-dropzone
                  :fileUpload="true"
                  :data="Object(asset.files)"
                  :id="asset.id"
                  @delete-download="deleteDownload"
                  @image-added="fileAdded"
                  @upload-error="uploadError"
                  @upload-in-progress="uploadInProgress"
                />

                <div class="spacer--20"></div>

                <card-item>
                  <ab-input-field
                    :label="$t('app.title')"
                    v-model="asset.title"
                    type="text"
                    :error="$v.asset.title.$error"
                    :errorMessage="$t('forms.add_valid_title')"
                  ></ab-input-field>
                  <ab-tags-field
                    v-model="selected_campaign"
                    :label="$t('assets.theme_or_campaign')"
                    :maxTags="1"
                    :availableTags="available_campaigns"
                    :alwaysShowAutocomplete="true"
                    :errorMessage="$t('forms.select_campaign')"
                  />

                  <ab-tags-field
                    v-model="selected_channel"
                    :label="$t('assets.channel')"
                    :maxTags="1"
                    :availableTags="available_channels"
                    :alwaysShowAutocomplete="true"
                  />

                  <ab-wysiwyg
                    :label="$t('app.description')"
                    v-model="asset.description"
                  ></ab-wysiwyg>

                  <ab-tags-field
                    v-model="selected_categories"
                    :label="$t('assets.asset_type')"
                    :maxTags="1"
                    :availableTags="available_categories"
                    :addOnlyFromAutocomplete="true"
                    :alwaysShowAutocomplete="true"
                    :errorMessage="$t('forms.select_category')"
                    :error="$v.selected_categories.$error"
                    customClass="ab-tags--category"
                    @tags-changed="tagsUpdated"
                  />

                  <ab-tags-field
                    v-model="selected_tags"
                    :label="$t('app.tags')"
                    :availableTags="available_tags"
                    :alwaysShowAutocomplete="true"
                    @tags-changed="tagsUpdated"
                  />

                  <ab-tags-field
                    v-model="selected_brand_elements"
                    :label="$t('assets.brand_elements')"
                    :availableTags="available_brand_elements"
                    :add-only-from-autocomplete="true"
                    :alwaysShowAutocomplete="true"
                    @tags-changed="tagsUpdated"
                  />
                  <ab-tags-field
                    v-model="selected_languages"
                    :label="$t('app.language')"
                    :maxTags="1"
                    :availableTags="available_languages"
                    :addOnlyFromAutocomplete="true"
                    :alwaysShowAutocomplete="true"
                    customClass="ab-tags--category"
                    @tags-changed="tagsUpdated"
                  />
                </card-item>
              </div>

              <div class="cell medium-4">
                <card-item :title="$t('assets.preview')">
                  <div
                    class="vue-dropzone-single"
                    :class="[$v.uploadedImage.$error ? 'has-error' : '']"
                  >
                    <ab-dropzone
                      v-if="asset.preview_image"
                      :data="asset.preview_image"
                      :id="asset.id"
                      :relation="'asset'"
                      :dropzoneReady="dropzoneReady"
                      :error="$v.uploadedImage.$error"
                      :errorMessage="$t('forms.select_preview_image')"
                      @image-added="imageAdded"
                      @image-removed="imageRemoved"
                      @upload-error="uploadError"
                      @upload-in-progress="uploadInProgress"
                    />
                  </div>
                </card-item>
                <div class="spacer--20"></div>
                <card-item :title="$t('assets.communication_date')">
                  <div class="range-picker">
                    <ab-date
                      v-model="date"
                      mode="range"
                      :label="$t('app.date')"
                    />
                  </div>
                </card-item>

                <div class="spacer--20"></div>

                <card-item :title="$t('assets.format')">
                  <ab-input-field
                    v-model="asset.format_width"
                    :label="$t('assets.width')"
                  />
                  <ab-input-field
                    v-model="asset.format_height"
                    :label="$t('assets.height')"
                  />
                  <ab-select
                    v-model="selected_unit"
                    :label="$t('assets.unit')"
                    :options="available_units"
                  />
                </card-item>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import backend from '@/backend'
import { dirtyRoute } from '@/views/common/mixins/routes/dirtyRoute'
import moment from 'moment'
import { minLength, required, sameAs } from 'vuelidate/lib/validators'

export default {
  name: 'AssetEdit',
  mixins: [dirtyRoute],
  data () {
    return {
      asset: [],
      selected_categories: [],
      selected_campaign: [],
      selected_languages: [],
      available_categories: [],
      available_languages: [],
      available_campaigns: [],
      selected_formats: [],
      available_formats: [],
      selected_channel: [],
      available_channels: [],
      selected_tags: [],
      available_tags: [],
      dropzoneReady: false,
      uploadedImage: true,
      selected_brand_elements: [],
      available_brand_elements: [],
      // format
      selected_unit: 'mm',
      available_units: [],
      // date
      date: {
        start: new Date(),
        end: new Date()
      },
      processing: false,
      isSaving: false
    }
  },
  created () {
    backend
      .get(`${process.env.VUE_APP_URL}/assets/${this.$route.params.pathName}`)
      .then((response) => {
        this.asset = response.data
        for (let i = this.asset.tags.length - 1; i >= 0; i--) {
          this.selected_tags.push({
            text: this.asset.tags[i].name,
            classes: 'tag'
          })
        }
        for (let j = this.asset.brand_elements.length - 1; j >= 0; j--) {
          this.selected_brand_elements.push({
            text: this.asset.brand_elements[j].title,
            slug: this.asset.brand_elements[j].slug
          })
        }

        this.selected_categories.push({
          text: this.asset.category.name,
          classes: 'tag'
        })

        if (this.asset.campaign) {
          this.selected_campaign.push({
            text: this.asset.campaign.name,
            classes: 'tag'
          })
        }

        if (this.asset.channel) {
          this.selected_channel.push({
            text: this.asset.channel.name,
            classes: 'tag'
          })
        }

        if (this.asset.unit) {
          this.selected_unit = this.asset.unit.name
        }

        if (!this.asset.preview_image) {
          this.asset.preview_image = {}
        }

        this.date = {}
        this.date.start = moment(String(this.asset.date_from)).toDate()
        this.date.end = moment(String(this.asset.date_to)).toDate()

        this.dropzoneReady = true
        document.title = 'ABRND | Assets - ' + response.data.title
        this.$nextTick(() => {
          this.dirty = false
        })
      })
    backend.get(`${process.env.VUE_APP_URL}/tags`).then((response) => {
      const autoCompleteTags = []
      for (const key in response.data) {
        const tag = response.data[key]
        if (tag.name !== '') {
          const tagData = {
            name: tag.name,
            type: 'tag',
            text: tag.name
          }
          autoCompleteTags.push(tagData)
        }
      }
      this.available_tags = autoCompleteTags.sort((a, b) =>
        a.name > b.name ? 1 : -1
      )
    })
    backend
      .get(`${process.env.VUE_APP_URL}/brand-elements`)
      .then((response) => {
        this.available_brand_elements = []
        for (let i = response.data.length - 1; i >= 0; i--) {
          this.available_brand_elements.push({
            text: response.data[i].title,
            slug: response.data[i].slug
          })
        }
      })
    backend
      .get(`${process.env.VUE_APP_URL}/assets/categories`)
      .then((response) => {
        this.available_categories = []
        for (let i = response.data.length - 1; i >= 0; i--) {
          this.available_categories.push({ text: response.data[i].name })
        }
      })

    backend.get(`${process.env.VUE_APP_URL}/assets/units`).then((response) => {
      this.available_units = []

      for (let i = response.data.length - 1; i >= 0; i--) {
        this.available_units.push({
          title: response.data[i].name,
          value: response.data[i].name
        })
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/campaigns`).then((response) => {
      this.available_campaigns = []
      for (let i = response.data.length - 1; i >= 0; i--) {
        this.available_campaigns.push({ text: response.data[i].name })
      }
    })

    backend.get(`${process.env.VUE_APP_URL}/channels`).then((response) => {
      this.available_channels = []
      for (let i = response.data.length - 1; i >= 0; i--) {
        this.available_channels.push({ text: response.data[i].name })
      }
    })

    backend
      .get(`${process.env.VUE_APP_URL}/assets/languages`)
      .then((response) => {
        this.available_languages = []
        for (let i = response.data.length - 1; i >= 0; i--) {
          this.available_languages.push({
            text: response.data[i].name,
            code: response.data[i].code
          })
          if (this.asset.language === response.data[i].code) {
            this.selected_languages.push({
              text: response.data[i].name,
              code: response.data[i].code
            })
          }
        }
      })

    this.$setupKeypress('s', this.formSubmit)
  },
  validations: {
    asset: {
      title: {
        required,
        minLength: minLength(1)
      }
    },
    uploadedImage: {
      required,
      sameAs: sameAs(() => true)
    },
    selected_categories: {
      required
    }
  },
  methods: {
    tagsUpdated () {
      this.dirty = true
    },
    imageAdded () {
      this.processing = false
      this.uploadedImage = true
    },
    fileAdded () {
      this.processing = false
    },
    uploadInProgress () {
      this.processing = true
    },
    uploadError () {
      this.processing = false
    },
    imageRemoved () {
      this.dirty = true
      this.uploadedImage = false
    },
    dateFormat (value, format) {
      if (value == null) {
        return moment().format(format)
      }
      return moment(String(value)).format(format)
    },
    deleteDownload: function (index) {
      this.asset.files.splice(index, 1)
    },
    cancelEditAsset (path) {
      const url = path.replace('/edit', '')
      this.$router.push(url)
    },
    removeAsset (path) {
      this.dirty = false
      this.$modal.show('dialog', {
        title: '',
        text: this.$t('app.remove_item'),
        buttons: [
          {
            title: this.$t('app.cancel'),
            handler: () => {
              this.$modal.hide('dialog')
            }
          },
          {
            title: this.$t('app.remove_item'),
            default: true,
            handler: () => {
              backend
                .delete(`${process.env.VUE_APP_URL}/assets/${path}`)
                .then(() => {
                  this.$notify({
                    group: 'custom-template',
                    title: false,
                    position: 'bottom left',
                    text: this.$t('assets.asset_removed')
                  })
                  this.$modal.hide('dialog')
                  this.$router.push({ name: 'AssetList' })
                })
            }
          }
        ]
      })
    },
    formSubmit: function () {
      this.$v.$touch()
      if (!this.$v.$invalid) {
        this.isSaving = true
        this.asset.category = this.selected_categories[0].text
        this.asset.tags = this.selected_tags
        this.asset.brand_elements = this.selected_brand_elements

        if (this.selected_languages.length === 1) {
          this.asset.language = this.selected_languages[0].code
        } else {
          this.asset.language = null
        }

        if (this.selected_campaign.length === 1) {
          this.asset.campaign = this.selected_campaign[0].text
        } else {
          this.asset.campaign = null
        }

        if (this.selected_channel.length === 1) {
          this.asset.channel = this.selected_channel[0].text
        } else {
          this.asset.channel = null
        }

        if (this.selected_unit) {
          this.asset.unit = this.selected_unit
        }

        if (this.date == null || this.date.start == null) {
          this.asset.date_from = null
        } else {
          this.asset.date_from = moment(String(this.date.start)).format(
            'YYYY-MM-DD'
          )
        }

        if (this.date == null || this.date.end == null) {
          this.asset.date_to = null
        } else {
          this.asset.date_to = moment(String(this.date.end)).format(
            'YYYY-MM-DD'
          )
        }

        backend
          .put(
            `${process.env.VUE_APP_URL}/assets/${this.$route.params.pathName}`,
            this.asset
          )
          .then(() => {
            this.dirty = false
            this.$router.push({
              name: 'AssetDetail',
              params: { pathName: this.asset.slug }
            })
          })
          .catch(() => {
            this.isSaving = false
          })
      }
    }
  },
  watch: {
    asset: {
      handler (newVal, oldVal) {
        this.dirty = true
      },
      deep: true
    },
    date: {
      handler () {
        this.dirty = true
      }
    }
  }
}
</script>

<style lang="scss">
.vel-toolbar {
  display: none !important;
}
</style>
<style lang="scss" scoped>
.asset-preview {
  cursor: pointer;
}
</style>
